import React from 'react'
import Layout, {Grid} from '../components/layout'
import SEO from '../components/seo'

const Disclaimer = () => {
    return (
        <Layout>
            <SEO/>
            <Grid ncol='eight'>
                <h2 style={{marginTop: '20px'}}>免責事項</h2>
                <hr/>
                <div>
                    本サイトに掲載する情報が正確な情報になるよう細心の注意を払っておりますが、
                    本サイトのコンテンツの妥当性や正確性等について保証するものではなく、一切の責任を負いません。
                </div>
            </Grid>
        </Layout>
    );
}

export default Disclaimer;
